import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="loading">
        <div className="inner">
          <CircularProgress />
        </div>
      </div>
    );
  }
}

export default Loading;
