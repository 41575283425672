import React, { Component } from "react";
import MediaCard from "../components/mediaCard";

class BlogPosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    this.setState({
      fetching: true
    });
    const cacheData = sessionStorage.getItem("blogPostsData");

    if (cacheData) {
      this.setState({
        data: JSON.parse(cacheData),
        fetching: false
      });
    }

    const response = await fetch(`https://wifininja.it/admin/api/collections/get/blog`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        lang: this.props.locale,
        limit: this.props.limit,
        sort: { _created: -1 }
      })
    });
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();
    sessionStorage.setItem("blogPostsData", JSON.stringify(jsonData));

    if (cacheData !== jsonData) {
      this.setState({
        data: jsonData,
        fetching: false
      });
    }
  }

  async componentDidMount() {
    await this.fetchData();
  }

  render() {
    if (!this.state.fetching) {
      const data = this.state.data.entries;
      //  console.log(data);
      return (
        <>
          <div className="cardsholder">
            {data.map((card, i) => {
              return card.published ? <MediaCard locale={this.props.locale} key={i} data={card} collection="blog" /> : null;
            })}
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}

export default BlogPosts;
