import React, { Component } from "react";
import Layout from "../layouts/layout";
import Loading from "../components/loading";
import MediaCard from "../components/mediaCard";
import BlogPosts from "../components/blogposts";
import Button from "@material-ui/core/Button";
import { Parallax } from "react-parallax";
import "../styles/home.css";
import ReactMarkdown from "react-markdown/with-html";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchDataModules() {
    this.setState({
      //fetching: true
    });
    const cacheDataModules = sessionStorage.getItem("modulesData");

    if (cacheDataModules) {
      this.setState({
        dataModules: JSON.parse(cacheDataModules),

        fetching: false
      });
    }

    const responseModules = await fetch("https://wifininja.it/admin/api/singletons/get/modules", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        //  lang: this.props.locale,
        populate: 1
      })
    });
    if (!responseModules.ok) {
      return;
    }
    const jsonDataModules = await responseModules.json();
    sessionStorage.setItem("modulesData", JSON.stringify(jsonDataModules));

    if (cacheDataModules !== jsonDataModules) {
      this.setState({
        dataModules: jsonDataModules
        //fetching: false
      });
    }
    //return data;
    this.tagManagerArgs = {
      gtmId: this.state.dataModules.gtmid
    };

    TagManager.initialize(this.tagManagerArgs);
  }

  async fetchData() {
    window.scroll(0, 0);
    this.setState({
      fetching: true
    });
    const cacheData = sessionStorage.getItem("homeData");

    if (cacheData) {
      this.setState({
        data: JSON.parse(cacheData),
        fetching: false
      });
    }

    const response = await fetch("https://wifininja.it/admin/api/singletons/get/home", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        lang: this.props.match.params.locale,
        populate: 1
      })
    });
    if (!response.ok) {
      return;
    }

    const jsonData = await response.json();
    sessionStorage.setItem("homeData", JSON.stringify(jsonData));

    if (cacheData !== jsonData) {
      this.setState({
        data: jsonData,

        fetching: false
      });
    }

    //return data;
  }

  async componentDidMount() {
    await this.fetchData();
    await this.fetchDataModules();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      await this.fetchData();
      await this.fetchDataModules();
    }
  }

  render() {
    const data = this.state.data;
    return (
      <Layout url={this.props.match.url} locale={this.props.match.params.locale}>
        {!this.state.fetching ? (
          <div className="home loader">
            <Helmet>
              <html lang={this.props.match.params.locale ? this.props.match.params.locale : "it"} />
              <meta charSet="utf-8" />
              <title>Home - WifiNinja</title>
              <meta name="description" content={data.meta_description} />
            </Helmet>
            <Parallax className="imageprimary" blur={0} bgImage={`https://wifininja.it${data.image_primary.path}`} bgImageAlt="primary" strength={100}>
              <div className="parallaxheight">
                <div className="parallaxinner">
                  <div style={{ color: "#fff" }}>
                    <ReactMarkdown escapeHtml={false} source={data.image_primary_text} />
                  </div>
                </div>
              </div>
            </Parallax>
            <div className="spacer2" />
            <h2 className="textcenter">{data.label_segments}</h2>
            <div className="cardsholder">
              {data.cards_segments.map((card, i) => {
                return <MediaCard key={i} data={card} collection="segments" locale={this.props.match.params.locale} />;
              })}
            </div>
            <Parallax className="imagesecondary" blur={0} bgImage={`https://wifininja.it${data.image_secondary.path}`} bgImageAlt="secondary" strength={100}>
              <div className="parallaxheight" />
            </Parallax>
            <div className="spacer2" />
            <h2 className="textcenter">{data.label_offers}</h2>
            <div className="cardsholder">
              {data.cards_offers.map((card, i) => {
                return <MediaCard key={i} data={card} collection="offers" locale={this.props.match.params.locale} />;
              })}
            </div>
            <div className="blog">
              <div className="spacer2" />

              <h2 className="textcenter">{data.label_blog}</h2>
              <BlogPosts limit={4} locale={this.props.match.params.locale} />
              <div className="readall">
                <Button
                  color="primary"
                  variant="contained"
                  component={Link}
                  to={!this.props.match.params.locale ? "/blog" : `/${this.props.match.params.locale}/blog`}
                >
                  {!this.props.match.params.locale ? `Tutte Le ${data.label_blog}` : `All ${data.label_blog}`}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </Layout>
    );
  }
}

export default Home;
