import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

const styles = {
  card: {
    width: 300,
    verticalAlign: "top",
    position: "relative"
  },

  // cardContent: {
  //   paddingBottom: "2rem"
  // },
  media: {
    height: 300
  },
  cardActions: {
    // position: "absolute",
    // bottom: 0
  }
};

function MediaCard(props) {
  const { classes } = props;
  const data = props.data;

  const dateobj = new Date(data._modified * 1000);
  const date = dateobj.toLocaleDateString("it-IT");
  return (
    <Fade left>
      <Card className={classes.card}>
        <CardActionArea
          style={{ minHeight: data.card_height }}
          component={Link}
          to={!props.locale ? `/${props.collection}/${data.url_handle}` : `/${props.locale}/${props.collection}/${data.url_handle}`}
        >
          <CardMedia className={classes.media} image={`https://wifininja.it/admin/storage/uploads${data.excerpt_image.path}`} />
          <CardContent className={classes.cardContent}>
            {props.collection === "blog" ? <p style={{ color: "#ccc" }}>{date}</p> : null}
            <Typography gutterBottom variant="h5" component="h2">
              {data.title}
            </Typography>
            <Typography component="span" className="cardexcerpt">
              <ReactMarkdown source={data.excerpt} />
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={data.card_height ? classes.cardActions : null} style={{ display: "block", textAlign: "right" }}>
          <Button
            component={Link}
            to={!props.locale ? `/${props.collection}/${data.url_handle}` : `/${props.locale}/${props.collection}/${data.url_handle}`}
            size="small"
            color="secondary"
          >
            {!props.locale ? (props.collection === "blog" ? "Leggi di più" : "Scopri di più") : props.collection === "blog" ? "Read More" : "Learn More"}
          </Button>
        </CardActions>
      </Card>
    </Fade>
  );
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MediaCard);
