import React, { Component } from "react";
import Layout from "../layouts/layout";
import Loading from "../components/loading";
import { Parallax } from "react-parallax";
import ReactMarkdown from "react-markdown/with-html";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";

import "../styles/mailchimp.css";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      data: [],
      dataModules: []
      // email: "",
      // nome: "",
      // cognome: "",
      // telefono: "",
      // messaggio: "",
      // ninjanews: ""
    };
  }

  async fetchDataModules() {
    const cacheDataModules = sessionStorage.getItem("modulesData");

    if (cacheDataModules) {
      this.setState({
        dataModules: JSON.parse(cacheDataModules)
      });
    }

    const responseModules = await fetch("https://wifininja.it/admin/api/singletons/get/modules", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        //  lang: this.props.locale,
        populate: 1
      })
    });
    if (!responseModules.ok) {
      return;
    }
    const jsonDataModules = await responseModules.json();
    sessionStorage.setItem("modulesData", JSON.stringify(jsonDataModules));

    if (cacheDataModules !== jsonDataModules) {
      this.setState({
        dataModules: jsonDataModules
      });
    }
  }

  async fetchData() {
    window.scroll(0, 0);

    const cacheData = sessionStorage.getItem("contactData");

    if (cacheData) {
      this.setState({
        data: JSON.parse(cacheData)
      });
    }

    const response = await fetch("https://wifininja.it/admin/api/singletons/get/contact", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        lang: this.props.match.params.locale
      })
    });
    if (!response.ok) {
      return;
    }

    const jsonData = await response.json();
    sessionStorage.setItem("contactData", JSON.stringify(jsonData));

    if (cacheData !== jsonData) {
      this.setState({
        data: jsonData
      });
    }
  }

  // handleField = name => event => {
  //   event.preventDefault();
  //   this.setState({ [name]: event.target.value });
  // };
  //
  // handleCheck = name => event => {
  //   this.setState({ [name]: event.target.checked });
  // };

  handleSubmit = e => {
    //e.preventDefault();
    this.tagManagerArgs = {
      gtmId: this.state.dataModules.gtmid,
      dataLayer: {
        email: document.getElementById("MERGE0").value,
        nome: document.getElementById("MERGE1").value,
        cognome: document.getElementById("MERGE2").value,
        telefono: document.getElementById("MERGE5").value,
        messaggio: document.getElementById("MERGE3").value,
        ninjanews: document.getElementById("gdpr_40087").checked
      }
    };
    TagManager.initialize(this.tagManagerArgs);
  };

  async componentDidMount() {
    await this.fetchData();
    await this.fetchDataModules();
    await this.setState({
      ready: true
    });
    this.tagManagerArgs = {
      gtmId: this.state.dataModules.gtmid,
      dataLayer: {
        // email: this.state.email,
        // nome: this.state.nome,
        // cognome: this.state.cognome,
        // telefono: this.state.telefono,
        // messaggio: this.state.messaggio,
        // ninjanews: this.state.ninjanews
      }
    };
    await TagManager.initialize(this.tagManagerArgs);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      await this.fetchData();
      await this.fetchDataModules();
      await this.setState({
        ready: true
      });
    }
  }

  render() {
    const data = this.state.data;

    return (
      <Layout url={this.props.match.url} locale={this.props.match.params.locale}>
        {this.state.ready ? (
          <div className="page loader">
            <Helmet>
              <html lang={this.props.match.params.locale ? this.props.match.params.locale : "it"} />
              <meta charSet="utf-8" />
              <title>Contact - WifiNinja</title>
            </Helmet>
            <Parallax
              className="imageprimary"
              blur={0}
              bgImage={`https://wifininja.it/admin/storage/uploads${data.image.path}`}
              bgImageAlt="primary"
              strength={100}
            >
              <div style={{ height: "40vh" }} />
            </Parallax>
            <div className="content form">
              <div className="maincontent">
                <h1 key="h1">{data.form_title}</h1>
                <div className="thecell padding" style={{ textAlign: "center" }}>
                  <ReactMarkdown escapeHtml={false} source={data.content} />
                </div>
                <form action="https://wifininja.us19.list-manage.com/subscribe/post" method="POST" id="mailchimp_form" onSubmit={this.handleSubmit}>
                  <input type="hidden" name="u" value="94ed8388a502407e7b4e7f7c1" />
                  <input type="hidden" name="id" value="800524ee9c" />

                  <div style={{ display: "none" }} className="field-shift" aria-label="Please leave the following three fields empty">
                    <label htmlFor="b_name">Name: </label>
                    <input type="text" name="b_name" tabIndex="-1" placeholder="Freddie" id="b_name" />

                    <label htmlFor="b_email">Email: </label>
                    <input type="email" name="b_email" tabIndex="-1" placeholder="youremail@gmail.com" id="b_email" />

                    <label htmlFor="b_comment">Comment: </label>
                    <textarea name="b_comment" tabIndex="-1" placeholder="Please comment" id="b_comment" />
                  </div>

                  <div id="mergeTable" className="mergeTable">
                    <div className="mergeRow dojoDndItem mergeRow-email" id="mergeRow-0">
                      <label htmlFor="MERGE0">
                        eMail <span className="req asterisk">*</span>
                      </label>
                      <div className="field-group">
                        <input type="email" autoCapitalize="off" autoCorrect="off" name="MERGE0" id="MERGE0" size="25" />
                      </div>
                    </div>

                    <div className="mergeRow dojoDndItem mergeRow-text" id="mergeRow-1">
                      <label htmlFor="MERGE1">Nome</label>
                      <div className="field-group">
                        <input type="text" name="MERGE1" id="MERGE1" size="25" />
                      </div>
                    </div>

                    <div className="mergeRow dojoDndItem mergeRow-text" id="mergeRow-2">
                      <label htmlFor="MERGE2">Cognome</label>
                      <div className="field-group">
                        <input type="text" name="MERGE2" id="MERGE2" size="25" />
                      </div>
                    </div>

                    <div className="mergeRow dojoDndItem mergeRow-phone" id="mergeRow-5">
                      <label htmlFor="MERGE5">Telefono</label>
                      <div className="field-group">
                        <input type="text" name="MERGE5" id="MERGE5" size="25" />
                      </div>
                    </div>

                    <div className="mergeRow dojoDndItem mergeRow-text" id="mergeRow-3">
                      <label htmlFor="MERGE3">Come possiamo aiutarti?</label>
                      <div className="field-group">
                        <input type="text" name="MERGE3" id="MERGE3" size="25" />
                      </div>
                    </div>

                    <div id="mergeRow-gdpr" className="mergeRow gdpr-mergeRow">
                      <div className="gdpr-content">
                        <label id="gdpr-label">Ninja News</label>
                        <p id="gdpr-description"> </p>
                        <div>
                          <ul className="interestgroup_field checkbox-group">
                            <li className="!margin-bottom--lv2">
                              <label className="checkbox" htmlFor="gdpr_40087">
                                <input type="checkbox" id="gdpr_40087" name="gdpr[40087]" className="av-checkbox" />
                                <span>Siii, vorrei ricevere gratuitamente le notizie selezionate per me da wifiNinja.</span>
                              </label>
                            </li>
                          </ul>
                        </div>

                        <p id="gdpr-legal">
                          Ti ricordiamo che puoi cancellarti in qualsiasi momento cliccando sul link in fondo alle email. La nota sotto in inglese ricorda che
                          Mailchimp è la piattaforma che usiamo per il marketing e che i dati inseriti saranno gestiti dai loro sistemi. Per maggiori
                          informazioni sulla privacy ti preghiamo di visitare il nostro sito.
                        </p>
                      </div>
                      <div className="gdpr-footer">
                        <a href="https://www.mailchimp.com/gdpr" target="_blank" without="true" rel="noopener noreferrer">
                          <img src="https://cdn-images.mailchimp.com/icons/mailchimp-gdpr.svg" alt="GDPR" />
                        </a>
                        <p>
                          We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred
                          to Mailchimp for processing.{" "}
                          <a href="https://mailchimp.com/legal/" target="_blank" without="true" rel="noopener noreferrer">
                            Learn more about Mailchimp's privacy practices here.
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="submit_container clear">
                    <input id="mailchimp_send" type="submit" className="formEmailButton" name="submit" value="INVIA" />
                  </div>
                  <input type="hidden" name="ht" value="5fd06d89cd4fd1a7fe2b7e959a5163a834871719:MTU2MTE5MjAwMS4wMTAy" />
                  <input type="hidden" name="mc_signupsource" value="hosted" />
                </form>
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </Layout>
    );
  }
}
export default Contact;
