import React, { Component } from "react";

import "../styles/page.css";
import ReactMarkdown from "react-markdown";
import Loading from "../components/loading";
import TagManager from "react-gtm-module";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    window.scroll(0, 0);
    this.setState({
      fetching: true
    });

    const response = await fetch("https://wifininja.it/admin/api/collections/get/pages", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        lang: this.props.match.params.locale,
        filter: { url_handle: "cookie-policy" }
      })
    });
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData.entries[0],
      fetching: false
    });

    //return data;
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      await this.fetchData();
    }
  }

  render() {
    const data = this.state.data;
    return (
      <div>
        {!this.state.fetching ? (
          <div className="content">
            <div className="maincontent">
              <h3 key="h3">{data.title}</h3>
              <ReactMarkdown source={data.content} />
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

export default Page;
