import React, { Component } from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import LanguageIcon from "@material-ui/icons/Language";
import "../styles/drawerList.css";

class DrawerList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handelize(str) {
    return str
      .toLowerCase()
      .replace(/[^\w\u00C0-\u024f]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }

  render() {
    return (
      <div className="drawerlist">
        <List>
          <ListItem button key="Home" component={Link} to={!this.props.locale ? `/` : `/${this.props.locale}`}>
            <ListItemText primary="Home" />
          </ListItem>
          <Divider />
          <ListItem className="listitemlabel" key={this.props.data.label_nav_segments}>
            <ListItemText primary={this.props.data.label_nav_segments} />
          </ListItem>
          {this.props.data.nav_segments.map((item, i) => {
            return (
              <ListItem
                button
                key={item.title}
                component={Link}
                to={!this.props.locale ? `/segments/${item.url_handle}` : `/${this.props.locale}/segments/${item.url_handle}`}
              >
                <ListItemText primary={item.title} />
              </ListItem>
            );
          })}
          <Divider />
          <ListItem className="listitemlabel" key={this.props.data.label_nav_offers}>
            <ListItemText primary={this.props.data.label_nav_offers} />
          </ListItem>

          {this.props.data.nav_offers.map((item, i) => {
            return (
              <ListItem
                button
                key={item.title}
                component={Link}
                to={!this.props.locale ? `/offers/${item.url_handle}` : `/${this.props.locale}/offers/${item.url_handle}`}
              >
                <ListItemText primary={item.title} />
              </ListItem>
            );
          })}
          <Divider />
          {this.props.data.nav_pages.map((item, i) => {
            return (
              <ListItem
                button
                key={item.title}
                component={Link}
                to={!this.props.locale ? `/pages/${item.url_handle}` : `/${this.props.locale}/pages/${item.url_handle}`}
              >
                <ListItemText primary={item.title} />
              </ListItem>
            );
          })}
          <Divider />
          <ListItem button key={this.props.data.label_blog} component={Link} to={!this.props.locale ? "/blog" : `/${this.props.locale}/blog`}>
            <ListItemText primary={this.props.data.label_blog} />
          </ListItem>
          <Divider />
          <ListItem button key={this.props.dataContact.title} component={Link} to={!this.props.locale ? `/contact` : `/${this.props.locale}/contact`}>
            <ListItemText primary={this.props.dataContact.title} />
          </ListItem>

          {this.props.data.switchlang ? (
            <>
              <ListItem />
              <Divider />
              <ListItem className="listitemlabel">
                <LanguageIcon />
              </ListItem>
              <ListItem button key="Italiano" component={Link} to={`/`}>
                <ListItemText primary="Italiano" />
              </ListItem>
              <ListItem button key="English" component={Link} to={`/en`}>
                <ListItemText primary="English" />
              </ListItem>
            </>
          ) : null}
        </List>
      </div>
    );
  }
}

export default DrawerList;
