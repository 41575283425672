import React, { Component } from "react";

import "./App.css";
import "./styles/antimess.css";

import { Route, Switch } from "react-router-dom";
import Home from "./containers/home";
import Offer from "./containers/offer";
import Segment from "./containers/segment";
import Page from "./containers/page";
import Post from "./containers/post";
import Blog from "./containers/blog";
import Contact from "./containers/contact";
import CookiePolicy from "./containers/cookiepolicy";
// import { CSSTransition, TransitionGroup, Transition } from "react-transition-group";
//import { AnimatedSwitch } from "react-router-transition";
// const windowLocation = window.location.pathname.split("/")[1];
// var localeFromUrl = null;
// if (windowLocation.length === 2) {
//   localeFromUrl = windowLocation;
// }

// <Layout locale={localeFromUrl} url={window.location.pathname}>
//  </Layout>

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/offers/:id" component={Offer} />
          <Route path="/segments/:id" component={Segment} />
          <Route path="/pages/:id" component={Page} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/blog/:id" component={Post} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/cookie-policy" exact component={CookiePolicy} />

          <Route path="/:locale" exact component={Home} />
          <Route path="/:locale/offers/:id" component={Offer} />
          <Route path="/:locale/segments/:id" component={Segment} />
          <Route path="/:locale/pages/:id" component={Page} />
          <Route path="/:locale/blog" exact component={Blog} />
          <Route path="/:locale/blog/:id" component={Post} />
          <Route path="/:locale/contact" exact component={Contact} />
          <Route path="/:locale/cookie-policy" exact component={CookiePolicy} />
        </Switch>
      </div>
    );
  }
}

export default App;

/*
 * Check out the article explaining this code in detail
 * at https://medium.com/@stephen.cook/animations-with-react-router-8e97222e25e1
 */
