import React, { Component } from "react";
import MediaCard from "../components/mediaCard";

class CardsHolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    this.setState({
      fetching: true
    });
    const cacheData = sessionStorage.getItem(`cards_${this.props.collection}`);

    if (cacheData) {
      this.setState({
        data: JSON.parse(cacheData),
        fetching: false
      });
    }
    const response = await fetch(`https://wifininja.it/admin/api/collections/get/${this.props.collection}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        lang: this.props.locale
      })
    });
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();
    sessionStorage.setItem(`cards_${this.props.collection}`, JSON.stringify(jsonData));

    if (cacheData !== jsonData) {
      this.setState({
        data: jsonData,
        fetching: false
      });
    }
  }

  async componentDidMount() {
    await this.fetchData();
  }

  render() {
    if (!this.state.fetching) {
      const data = this.state.data.entries;
      //  console.log(data);
      return (
        <div className="cardsholder">
          {data.map((card, i) => {
            return this.props.current !== card._id ? <MediaCard key={i} data={card} locale={this.props.locale} collection={this.props.collection} /> : null;
          })}
        </div>
      );
    } else {
      return <div className="loading" />;
    }
  }
}

export default CardsHolder;
