import React, { Component } from "react";
import Header from "../containers/header";
import Footer from "../containers/footer";

class Layout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="layout">
        <Header locale={this.props.locale} url={this.props.url} />
        {this.props.children}
        <Footer locale={this.props.locale} url={this.props.url} />
      </div>
    );
  }
}

export default Layout;
