import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";

class SimpleMenu extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    return (
      <div className="simplemenu">
        <Button aria-owns={anchorEl ? "simple-menu" : undefined} aria-haspopup="true" onClick={this.handleClick} color="inherit">
          {this.props.menuLabel}
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          {this.props.menuItems.map((item, i) => {
            return (
              <MenuItem
                onClick={this.handleClose}
                key={i}
                component={Link}
                to={!this.props.locale ? `/${item._link}/${item.url_handle}` : `/${this.props.locale}/${item._link}/${item.url_handle}`}
              >
                {item.title}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

export default SimpleMenu;
