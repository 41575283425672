import React, { Component } from "react";
import Layout from "../layouts/layout";
import "../styles/page.css";
import { Helmet } from "react-helmet";

import BlogPosts from "../components/blogposts";
import TagManager from "react-gtm-module";

class Blog extends Component {
  async fetchDataModules() {
    this.setState({
      //fetching: true
    });
    const cacheDataModules = sessionStorage.getItem("modulesData");

    if (cacheDataModules) {
      this.setState({
        dataModules: JSON.parse(cacheDataModules),

        fetching: false
      });
    }

    const responseModules = await fetch("https://wifininja.it/admin/api/singletons/get/modules", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        //  lang: this.props.locale,
        populate: 1
      })
    });
    if (!responseModules.ok) {
      return;
    }
    const jsonDataModules = await responseModules.json();
    sessionStorage.setItem("modulesData", JSON.stringify(jsonDataModules));

    if (cacheDataModules !== jsonDataModules) {
      this.setState({
        dataModules: jsonDataModules
        //fetching: false
      });
    }
    //return data;
    this.tagManagerArgs = {
      gtmId: this.state.dataModules.gtmid
    };
    TagManager.initialize(this.tagManagerArgs);
  }

  async componentDidMount() {
    await this.fetchDataModules();
    window.scroll(0, 0);
  }

  render() {
    return (
      <Layout url={this.props.match.url} locale={this.props.match.params.locale}>
        <Helmet>
          <html lang={this.props.match.params.locale ? this.props.match.params.locale : "it"} />
          <meta charSet="utf-8" />
          <title>News - WifiNinja</title>
        </Helmet>
        <div style={{ paddingTop: "6rem" }}>
          <BlogPosts limit={0} locale={this.props.match.params.locale} />
        </div>
      </Layout>
    );
  }
}

export default Blog;
