import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SimpleMenu from "../components/simpleMenu";
import Drawer from "@material-ui/core/Drawer";
import DrawerList from "../components/drawerList";
import SwitchLang from "../components/switchLang";
import Button from "@material-ui/core/Button";
import "../styles/header.css";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 12
  }
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      fetching: true,
      data: [],
      left: false
    };
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  backtotop() {
    window.scroll(0, 0);
  }

  async fetchData() {
    this.setState({
      fetching: true
    });
    const cacheData = sessionStorage.getItem("headerData");
    const cacheDataContact = sessionStorage.getItem("contactData");

    if (cacheData && cacheDataContact) {
      this.setState({
        data: JSON.parse(cacheData),
        dataContact: JSON.parse(cacheDataContact),
        fetching: false
      });
    }

    const response = await fetch("https://wifininja.it/admin/api/singletons/get/header", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        lang: this.props.locale,
        populate: 1
      })
    });
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();
    sessionStorage.setItem("headerData", JSON.stringify(jsonData));

    const responseContact = await fetch("https://wifininja.it/admin/api/singletons/get/contact", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        lang: this.props.locale
      })
    });
    if (!responseContact.ok) {
      return;
    }
    const jsonDataContact = await responseContact.json();
    sessionStorage.setItem("contactData", JSON.stringify(jsonDataContact));

    if (cacheData !== jsonData || cacheDataContact !== jsonDataContact) {
      this.setState({
        data: jsonData,
        dataContact: jsonDataContact,

        fetching: false
      });
    }
    //return data;
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      await this.fetchData();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="header">
        <div className={classes.root}>
          <AppBar position="fixed" className="appbar">
            <Toolbar>
              <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.toggleDrawer("left", true)}>
                <MenuIcon />
              </IconButton>
              {!this.state.fetching ? (
                <>
                  <Typography variant="h6" color="inherit" className={classes.grow}>
                    <Link to={!this.props.locale ? `/` : `/${this.props.locale}`}>
                      <img
                        onClick={this.backtotop()}
                        src={`https://wifininja.it${this.state.data.logo.path}`}
                        alt="WifiNinja"
                        style={{ height: "40px", display: "block" }}
                      />
                    </Link>
                  </Typography>
                  <SimpleMenu
                    locale={this.props.locale}
                    menuLabel={this.state.data.label_nav_segments}
                    menuItems={this.state.data.nav_segments}
                    collection="segments"
                  />
                  <SimpleMenu
                    locale={this.props.locale}
                    menuLabel={this.state.data.label_nav_offers}
                    menuItems={this.state.data.nav_offers}
                    collection="offers"
                  />
                  {this.state.data.nav_pages.map((item, i) => {
                    return (
                      <Button
                        button="true"
                        className="navbutton"
                        key={item.title}
                        component={Link}
                        to={!this.props.locale ? `/pages/${item.url_handle}` : `/${this.props.locale}/pages/${item.url_handle}`}
                      >
                        {item.title}
                      </Button>
                    );
                  })}
                  <Button button="true" component={Link} to={!this.props.locale ? `/blog` : `/${this.props.locale}/blog`} className="navbutton lastbutton">
                    {this.state.data.label_blog}
                  </Button>
                  {this.state.data.switchlang ? <SwitchLang locale={this.props.locale} /> : null}
                  <Button
                    button="true"
                    component={Link}
                    to={!this.props.locale ? `/contact` : `/${this.props.locale}/contact`}
                    className="headercontactbutton"
                    variant="contained"
                    color="secondary"
                  >
                    {this.state.dataContact.title}
                  </Button>
                </>
              ) : null}
            </Toolbar>
          </AppBar>
        </div>
        <Drawer open={this.state.left} onClose={this.toggleDrawer("left", false)}>
          <div tabIndex={0} role="button" onClick={this.toggleDrawer("left", false)} onKeyDown={this.toggleDrawer("left", false)}>
            <DrawerList dataContact={this.state.dataContact} data={this.state.data} locale={this.props.locale} />
          </div>
        </Drawer>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);

//export default Header;
