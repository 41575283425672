import React, { Component } from "react";
import "../styles/footer.css";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Loading from "../components/loading";
import { SocialIcon } from "react-social-icons";
import ReactMarkdown from "react-markdown/with-html";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataHeader: [],
      dataFooter: [],
      fetching: true
    };
  }

  async fetchData() {
    this.setState({
      fetching: true
    });
    const cacheDataHeader = sessionStorage.getItem("headerData");
    const cacheDataFooter = sessionStorage.getItem("footerData");

    if (cacheDataHeader && cacheDataFooter) {
      this.setState({
        dataHeader: JSON.parse(cacheDataHeader),
        dataFooter: JSON.parse(cacheDataFooter),
        fetching: false
      });
    }
    const responseHeader = await fetch("https://wifininja.it/admin/api/singletons/get/header", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        lang: this.props.locale,
        populate: 1
      })
    });
    if (!responseHeader.ok) {
      return;
    }
    const headerData = await responseHeader.json();

    const responseFooter = await fetch("https://wifininja.it/admin/api/singletons/get/footer", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        lang: this.props.locale,
        populate: 1
      })
    });
    if (!responseFooter.ok) {
      return;
    }
    const footerData = await responseFooter.json();

    sessionStorage.setItem("headerData", JSON.stringify(headerData));
    sessionStorage.setItem("footerData", JSON.stringify(footerData));

    if (cacheDataHeader !== headerData || cacheDataFooter !== footerData) {
      this.setState({
        dataHeader: headerData,
        dataFooter: footerData,
        fetching: false
      });
    }

    //return data;
  }

  async componentDidMount() {
    await this.fetchData();
  }

  // async componentDidUpdate(prevProps) {
  //   if (this.props.url !== prevProps.url) {
  //     await this.fetchData();
  //   }
  // }

  render() {
    const dataHeader = this.state.dataHeader;
    const dataFooter = this.state.dataFooter;

    return (
      <div className="footer">
        {!this.state.fetching ? (
          <div className="footercontent">
            <div className="thegrid uniform">
              <div className="thecell x33 s-x100 padding">
                <List>
                  <ListItem className="listitemlabel">
                    <ListItemText primary="Info" />
                  </ListItem>
                  {dataFooter.pages.map((page, i) => {
                    return (
                      <ListItem
                        key={i}
                        button
                        component={Link}
                        to={!this.props.locale ? `/pages/${page.url_handle}` : `/${this.props.locale}/pages/${page.url_handle}`}
                      >
                        <ListItemText primary={page.title} />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
              <div className="thecell x33 s-x100 padding">
                <List>
                  <ListItem className="listitemlabel">
                    <ListItemText primary={dataHeader.label_nav_segments} />
                  </ListItem>
                  {dataHeader.nav_segments.map((page, i) => {
                    return (
                      <ListItem
                        key={i}
                        button
                        component={Link}
                        to={!this.props.locale ? `/segments/${page.url_handle}` : `/${this.props.locale}/segments/${page.url_handle}`}
                      >
                        <ListItemText primary={page.title} />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
              <div className="thecell x33 s-x100 padding">
                <List>
                  <ListItem className="listitemlabel">
                    <ListItemText primary={dataHeader.label_nav_offers} />
                  </ListItem>
                  {dataHeader.nav_offers.map((page, i) => {
                    return (
                      <ListItem
                        key={i}
                        button
                        component={Link}
                        to={!this.props.locale ? `/offers/${page.url_handle}` : `/${this.props.locale}/offers/${page.url_handle}`}
                      >
                        <ListItemText primary={page.title} />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
              <div className="thecell x33 s-x100 padding">
                <div style={{ paddingLeft: "1rem" }}>
                  <ReactMarkdown escapeHtml={false} source={this.state.dataFooter.extra_footer} />
                </div>
              </div>
              <div className="thecell x33 s-x100 padding">
                <div className="thecell padding">
                  <strong style={{ display: "block" }}>{this.state.dataFooter.label_followus}</strong>
                  <br />
                  {this.state.dataFooter.social_facebook ? (
                    <span className="socialicon">
                      <SocialIcon target="_blank" bgColor="#ffffff" fgColor="#000000" url={this.state.dataFooter.social_facebook} />
                    </span>
                  ) : null}

                  {this.state.dataFooter.social_twitter ? (
                    <span className="socialicon">
                      <SocialIcon target="_blank" bgColor="#ffffff" fgColor="#000000" url={this.state.dataFooter.social_twitter} />
                    </span>
                  ) : null}
                  {this.state.dataFooter.social_instagram ? (
                    <span className="socialicon">
                      <SocialIcon target="_blank" bgColor="#ffffff" fgColor="#000000" url={this.state.dataFooter.social_instagram} />
                    </span>
                  ) : null}
                  {this.state.dataFooter.social_linkedin ? (
                    <span className="socialicon">
                      <SocialIcon target="_blank" bgColor="#ffffff" fgColor="#000000" url={this.state.dataFooter.social_linkedin} />
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

export default Footer;
