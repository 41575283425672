import React, { Component } from "react";
import { Parallax } from "react-parallax";
import Layout from "../layouts/layout";
import "../styles/page.css";
import ReactMarkdown from "react-markdown";
import Loading from "../components/loading";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchDataModules() {
    this.setState({
      //fetching: true
    });
    const cacheDataModules = sessionStorage.getItem("modulesData");

    if (cacheDataModules) {
      this.setState({
        dataModules: JSON.parse(cacheDataModules),

        fetching: false
      });
    }

    const responseModules = await fetch("https://wifininja.it/admin/api/singletons/get/modules", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        //  lang: this.props.locale,
        populate: 1
      })
    });
    if (!responseModules.ok) {
      return;
    }
    const jsonDataModules = await responseModules.json();
    sessionStorage.setItem("modulesData", JSON.stringify(jsonDataModules));

    if (cacheDataModules !== jsonDataModules) {
      this.setState({
        dataModules: jsonDataModules
        //fetching: false
      });
    }
    //return data;
    this.tagManagerArgs = {
      gtmId: this.state.dataModules.gtmid
    };
    TagManager.initialize(this.tagManagerArgs);
  }

  async fetchData() {
    window.scroll(0, 0);
    this.setState({
      fetching: true
    });
    const cacheData = sessionStorage.getItem(`pageData_${this.props.match.params.id}`);

    if (cacheData) {
      this.setState({
        data: JSON.parse(cacheData),
        fetching: false
      });
    }
    const response = await fetch("https://wifininja.it/admin/api/collections/get/pages", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "2e9c41348cad52615a5f5696295598"
      },
      body: JSON.stringify({
        lang: this.props.match.params.locale,
        filter: { url_handle: this.props.match.params.id }
      })
    });
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();
    sessionStorage.setItem(`pageData_${this.props.match.params.id}`, JSON.stringify(jsonData.entries[0]));

    if (cacheData !== jsonData.entries[0]) {
      this.setState({
        data: jsonData.entries[0],
        fetching: false
      });
    }
    //return data;
  }

  async componentDidMount() {
    await this.fetchData();
    await this.fetchDataModules();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      await this.fetchData();
      await this.fetchDataModules();
    }
  }

  render() {
    const data = this.state.data;
    return (
      <Layout url={this.props.match.url} locale={this.props.match.params.locale}>
        {!this.state.fetching ? (
          <div className="page loader">
            <Helmet>
              <html lang={this.props.match.params.locale ? this.props.match.params.locale : "it"} />
              <meta charSet="utf-8" />
              <title>{data.title} - WifiNinja</title>
              <meta name="description" content={data.meta_description} />
            </Helmet>
            <Parallax
              className="imageprimary"
              blur={0}
              bgImage={`https://wifininja.it/admin/storage/uploads${data.image.path}`}
              bgImageAlt="primary"
              strength={100}
            >
              <div style={{ height: "40vh" }} />
            </Parallax>
            <div className="content">
              <div className="maincontent">
                <h1 key="h1">{data.title}</h1>
                <ReactMarkdown source={data.content} />
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </Layout>
    );
  }
}

export default Page;
