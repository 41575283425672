import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import LanguageIcon from "@material-ui/icons/Language";

class SwitchLang extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    return (
      <div className="simplemenu">
        <Button aria-owns={anchorEl ? "simple-menu" : undefined} aria-haspopup="true" onClick={this.handleClick} color="inherit">
          <LanguageIcon />
          <span style={{ marginLeft: "6px" }}>{!this.props.locale ? "IT" : this.props.locale.toUpperCase()}</span>
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem onClick={this.handleClose} key="Italiano" component={Link} to={`/`}>
            Italiano
          </MenuItem>
          <MenuItem onClick={this.handleClose} key="English" component={Link} to={`/en`}>
            English
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default SwitchLang;
